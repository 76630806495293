import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery';
global.$ = $;
global.jQuery = $;


//select2
import select2 from 'select2';
// import 'select2/dist/css/select2.min.css';
// import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';

$(document).ready(function () {
  $('select').select2({
    theme: "bootstrap4",
    width: "style",
    minimumResultsForSearch: 20,
  });
});
//jquery easing
import "jquery.easing/jquery.easing";
import 'bootstrap/dist/js/bootstrap.bundle';

import '@fortawesome/fontawesome-free/js/all'
// import "@fortawesome/fontawesome-free/css/all.css";

import dt from 'datatables.net/js/jquery.dataTables';
import 'datatables.net-bs4/js/dataTables.bootstrap4';

Rails.start()
// Turbolinks.start()
ActiveStorage.start()
